// Color of the background
$global-background-color: #eeeeee;
$global-background-color-dark: #000000;

// ========== Header ========== //
// Height of the header
$header-height: 3.5rem;

// Color of the header background
$header-background-color: #f8f8f8;
$header-background-color-dark: #16131e;

// Font style of the header title
$header-title-font-family: $global-font-family;
$header-title-font-size: 1.5rem;

// Color of the active menu item
$menu-active-color: #161209;
$menu-active-color-dark: #fff;

// Color of the search background
$search-background-color: #e9e9e9;
$search-background-color-dark: #363636;


// Radius of the border
// $global-border-radius: 15px !default;